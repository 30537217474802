<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("branch_picker_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('branch_picker_page.form_desc')"
    ></div>
    <!-- <div  class="page__content" v-if="getModuleConfig('branch_picker_page.content')" v-html="getModuleConfig('branch_picker_page.content')"></div> -->
    <div v-if="filter != null">
      <coupon-filters
        class="s-mb-3"
        :options="filterOptions"
        v-model="filter"
      ></coupon-filters>

      <div>
        <Loading v-if="showLoading" loading-message="載入中..." />
        <div v-else-if="!showLoading && filter == 'qrcode'">
          <QRCodeScanner @onScan="onScan" />
          <input
            class="search__input mx-auto w-100 mt-3"
            disabled
            v-model="barcode"
          />
        </div>
        <div v-else-if="!showLoading && filter == 'manual'">
          <input class="search__input m-auto w-100" v-model="barcode" />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="col-12 px-2" v-for="(option, idx) in options" :key="idx">
        <SharedRadio
          v-model="channel"
          :id="`proxy-${idx}`"
          :value="option.value"
          name="proxy"
        >
          {{ option.text }}
        </SharedRadio>
      </div>
    </div>
    <div
      v-if="
        getModuleConfig('branch_picker_page.button_text') ||
        getModuleConfig('branch_picker_page.cancel_button_text')
      "
      class="p-3 fixed-bottom bg-white footer"
    >
      <SharedButton class="s-btn-bg-primary mb-2" @click="bindStore">{{
        getModuleConfig("branch_picker_page.button_text")
      }}</SharedButton>
      <SharedButton variant="outline-dark" class="s-btn-outline-primary">{{
        getModuleConfig("branch_picker_page.cancel_button_text")
      }}</SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import branchMixin from "@/mixins/liff/branch";
import CouponFilters from "@/pages/Dashboard/Coupon/CouponFilters.vue";
import QRCodeScanner from "@/components/QRCodeScanner";
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import abbottAndMixin from "@/mixins/liff/proj/abbott-and";
import SharedRadio from "@/components/DynamicForm/Radio.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [branchMixin, abbottAndMixin],
  components: {
    Loading,
    SharedButton,
    CouponFilters,
    QRCodeScanner,
    SharedRadio,
  },
  data() {
    return {
      showLoading: false,
      options: [{ text: "特選藥局", value: "特選藥局" }],
      proxy: null,
      filter: null,
      barcode: null,
      channel: null
    };
  },

  computed: {
    ...mapGetters({
      isBindStoreMember: "projAbbottAnd/isBindStore",
    }),
    filterOptions() {
      return [
        {
          key: "qrcode",
          text: "QR Code 掃描",
        },
        {
          key: "manual",
          text: "手動輸入",
        },
      ];
    },
  },
  mounted() {
    if (this.couponType == "ipd") {
      if(!this.isBindStoreMember) {
        this.options.push({ text: "亞培官方旗艦店", value: "亞培官方旗艦店" });
        this.options.push({
          text: "MOMO亞培品牌旗艦館",
          value: "MOMO亞培品牌旗艦館",
        });
      }
    } else {
      this.filter = 'qrcode'
    }
  },

  methods: {
    async onScan(decodedText) {
      this.barcode = decodedText;
    },
    async bindStore() {
      if (this.couponType == "ipd") {
        await this.joinChannel(this.channel);
        if (this.channel == "特選藥局") {
          this.$router.push({ name: "LiffProjAbbottAndSendCoupon" });
        }
      } else {
        if (!this.barcode) {
          alert("請輸入或掃描店家代碼");
          return;
        }
        try {
          await abbottAndApi.bindStore(this.$route.params.orgCode, {
            store_code: this.barcode,
          });
          this.$router.push({ name: "LiffProjAbbottAndSendCoupon" });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  &__input {
    background: #ffffff;
    border-radius: 10px !important;
    border: 1px solid var(--liff-button-color);
    padding: 15px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }

    &:focus {
      border: 1px solid var(--liff-button-color);
      outline: none;
    }
  }
}
</style>
